import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import Link from 'next/link';

// Utils
import { cn } from 'utils';
import { replacePageSlug } from '../../../src/utils/pageSlug';

// Styles
import styles from './ResidencesBlock.module.css';

// Components
import Title from '../../atoms/Title/Title';
import ResidenceCard from '../../atoms/ResidenceCard/ResidenceCard';
import CardsCarousel from '../../atoms/CardsCarousel/CardsCarousel';
import Button from '../../atoms/Button/Button';

// Constants
import { URL_SEARCH_PAGES, URL_SLUGS_ACTIVITIES, URL_SLUGS_RESIDENCES } from '../../../src/constants';

function ResidencesBlock({
  title, subtitle, residences, theme, className,
}) {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const getSlugResidence = (slug, activity) => {
    if (slug && activity) {
      const cleanedSlug = replacePageSlug(slug, locale);
      return `/${URL_SLUGS_ACTIVITIES[locale][activity]}/${cleanedSlug}`;
    }
    return `/${URL_SLUGS_RESIDENCES[locale]}`;
  };

  return (
    <div className={cn([styles.wrapper, styles[theme], className])}>
      <div className={styles.container}>
        <Title
          title={title}
          subtitle={subtitle}
          theme={theme}
          className={styles.title}
        />
        <CardsCarousel list={residences} theme={theme} uniqueKey="slug" className={styles.carousel}>
          {({ data }) => {
            const cleanedLink = getSlugResidence(data?.slug, data?.activity);
            const residence = data?.residence?.data;

            return (
              <ResidenceCard
                key={residence?.pmsId}
                name={residence?.nameShort}
                address={residence?.address}
                price={residence?.minPrice > 0 ? t('residencesBlock.price', { price: residence?.minPrice }) : ''}
                media={residence?.media}
                slug={cleanedLink}
                theme={theme}
                TitleTag="h2"
                className={styles.card}
              />
            );
          }}
        </CardsCarousel>
        <Link href={`/${URL_SEARCH_PAGES[locale]}`} className={styles.link}>
          <Button icon="arrowForward" iconPosition="right">
            {t('residencesBlock.button')}
          </Button>
        </Link>
      </div>
    </div>
  );
}

ResidencesBlock.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  residences: PropTypes.arrayOf(
    PropTypes.shape({
      city: PropTypes.shape({}),
      address: PropTypes.string,
      price: PropTypes.string,
      media: PropTypes.shape({
        url: PropTypes.string,
        description: PropTypes.string,
      }),
      slug: PropTypes.string,
      theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
    }),
  ),
  theme: PropTypes.oneOf(['light', 'dark', 'neutral', '']),
  className: PropTypes.string,
};

ResidencesBlock.defaultProps = {
  title: '',
  subtitle: '',
  residences: [],
  theme: 'light',
  className: '',
};

export default ResidencesBlock;
